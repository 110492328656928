const menu = [
  // { title: "Home", url: "/" },
  { title: "Football", url: "category/football" },
  {
    title: "Basketball",
    url: "category/basketball",
  },
  {
    title: "UFC",
    url: "category/ufc",
  },
  {
    title: "Boxing",
    url: "category/boxing",
  },
  {
    title: "WWE",
    url: "category/wwe",
  },
  {
    title: "Academy",
    url: "category/academy",
  },
  {
    title: "More",
    url: "category/more",
  },
];

export default menu;
